import React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import socials from "../../constants/sozial-icons"
import variables from "../../constants/variables"

const Footer = ({ path }) => {
  return (
    <FooterComponent>
      <div className="d-flex flex-column flex-sm-row justify-content-around">
        <div className="mb-2 my-sm-auto">
          {socials.map((icon, index) => {
            return (
              <a
                key={index}
                href={icon.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {icon.icon}
              </a>
            )
          })}
        </div>
        <div className="mb-2 my-sm-auto">
          {path !== "/" && (
            <AniLink fade to="/">
              Home |
            </AniLink>
          )}
          {path !== "/impressum/" && (
            <AniLink fade to="/impressum/">
              {" "}
              Impressum
            </AniLink>
          )}
          {path !== "/datenschutz/" && path !== "/impressum/" && (
            <span> |</span>
          )}
          {path !== "/datenschutz/" && (
            <AniLink fade to="/datenschutz">
              {" "}
              Datenschutz
            </AniLink>
          )}
        </div>
        <div className="my-sm-auto">&copy; Heftige Webseiten</div>
      </div>
    </FooterComponent>
  )
}

const FooterComponent = styled.footer`
  margin-top: auto;
  background: ${variables.COLORS.mainBlue};
  padding: 2rem;
  text-align: center;
  color: ${variables.COLORS.mainWhite};
  a {
    color: ${variables.COLORS.mainWhite};
    text-decoration: none;
    svg {
      font-size: 2rem;
      margin-right: 0.5rem;
      background: transparent;
    }
  }
`

export default Footer
