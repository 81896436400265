import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"
import { Link } from "gatsby"
import {
  FaHome,
  FaUser,
  FaMapSigns,
  FaTools,
  FaConciergeBell,
  FaBook,
  FaRegAddressCard,
  FaGlobe,
} from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import variables from "../constants/variables"
import socials from "../constants/sozial-icons"

const thwLogo = graphql`
  query {
    logo: file(relativePath: { eq: "th-logoonly.png" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Navigation = props => {
  const data = useStaticQuery(thwLogo)
  const [isOpen, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen({ isOpen: false })
  }
  return (
    // Pass on our props
    <MenuWrapper>
      <Menu isOpen={isOpen} {...props}>
        <Link to="/#" className="mb-4" onClick={closeMenu}>
          <Image fixed={data.logo.childImageSharp.fixed} alt="" />
        </Link>
        <Link className="menu-item mb-2" to="/#" onClick={closeMenu}>
          <FaHome className="icon" /> Home
        </Link>
        <Link className="menu-item mb-2" to="/#aboutme" onClick={closeMenu}>
          <FaUser className="icon" /> Über mich
        </Link>
        <Link
          className="menu-item mb-2"
          to="/#performances"
          onClick={closeMenu}
        >
          <FaConciergeBell className="icon" /> Leistungen
        </Link>
        <Link className="menu-item mb-2" to="/#waytogo" onClick={closeMenu}>
          <FaMapSigns className="icon" /> Der Weg zur Homepage
        </Link>
        <Link className="menu-item mb-2" to="/#tools" onClick={closeMenu}>
          <FaTools className="icon" /> Werkzeug
        </Link>
        <Link className="menu-item mb-2" to="/#refs" onClick={closeMenu}>
          <FaBook className="icon" /> Referenzen
        </Link>
        <Link className="menu-item mb-2" to="/#contact" onClick={closeMenu}>
          <FaRegAddressCard className="icon" /> Kontakt
        </Link>
        <a href="https:\\www.heftigeapps.de" target="_blank" rel="noreferrer">
          <FaGlobe className="icon" />
          Heftige Apps
        </a>
        <div className="d-flex mt-3">
          <div>
            {socials.map((icon, index) => {
              return (
                <a
                  key={index}
                  href={icon.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {icon.icon}
                </a>
              )
            })}
          </div>
        </div>
      </Menu>
    </MenuWrapper>
  )
}

export default Navigation

const MenuWrapper = styled.div`
  a {
    color: ${variables.COLORS.mainWhite};
    svg {
      font-size: 1.5rem;
      margin-right: 0.5rem;
      background: transparent;
    }
  }
  .icon {
    padding-bottom: 3px;
  }
`
