import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import styled, { createGlobalStyle } from "styled-components"
import { device } from "../utils/breakpoints"
import { isBrowser, isMobile } from "react-device-detect"

import variables from "../constants/variables"

import BurgerMenu from "./Navigation"
import BrowserNavigation from "./BrowserNavigation"
import Footer from "./Footer/Footer"
import Whatsapp from "./Whatsapp"

const Layout = ({ children, path }) => {
  return (
    <>
      <GlobalStyle />
      {isMobile && (
        <MenuWrapper className="d-lg-none">
          <BurgerMenu />
        </MenuWrapper>
      )}
      {isBrowser && <BrowserNavigation />}
      <main id="page-wrap">
        {children}
        <Footer path={path} />
        <Whatsapp />
      </main>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Lato", sans-serif;
  color: ${variables.COLORS.mainBlack};
  background: ${variables.COLORS.mainWhite};
  line-height: 1.4;
  font-size: 0.8rem;
}
main {
	/* position: relative; */
  min-height: 100vh;
}
.whatsapp{
	position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
}

@media ${device.laptop} {
	.whatsapp{
		top:50%
	}
}

hr {
  background: linear-gradient(
    to right,
    ${variables.COLORS.lightBlue},
    ${variables.COLORS.primaryColor},
    ${variables.COLORS.mainYellow}
  ) !important;
  height: 3px !important;
  margin-bottom: 3rem !important;
}

@media ${device.laptop} {
	body {
		font-size: 1rem;
	}
}
`

const MenuWrapper = styled.div`
  a {
    outline: none;
  }
  .menu-item {
    color: ${variables.COLORS.mainWhite};
    text-decoration: none;
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 30px;
    top: 30px;
    button {
      outline: none;
      -moz-outline-style: none;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${variables.COLORS.mainWhite};
    border: 0.1px solid black;
    box-shadow: ${variables.SHADOWS.lightShadow};
    -moz-box-shadow: ${variables.SHADOWS.lightShadow};
    -webkit-box-shadow: ${variables.SHADOWS.lightShadow};
  }
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    transition: ${variables.TRANSITIONS.mainTransition};
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    button {
      outline: none;
    }
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: ${variables.COLORS.mainWhite};
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    min-width: none;
  }

  /* General sidebar styles */
  .bm-menu {
    background: rgba(20, 77, 101, 0.8);
    padding: 0.5em 0.5em 0;
    font-size: 1.4em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: transparent;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: ${variables.COLORS.mainWhite};
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.1) !important;
  }
`

export default Layout
