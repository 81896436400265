import React from "react"

import WhatsappImage from "../images/whatsapp.png"

const Whatsapp = () => (
  <div className="whatsapp">
    <a
      href="https://wa.me/message/4COQBGBD7QILD1"
      target="_blank"
      rel="noreferrer"
    >
      <img src={WhatsappImage} width="100" alt="Whatsapp logo" />
    </a>
  </div>
)

export default Whatsapp
