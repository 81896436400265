import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"

import links from "../constants/links"
import variables from "../constants/variables"
import socials from "../constants/sozial-icons"

const getImage = graphql`
  query {
    heftigLogo: file(relativePath: { eq: "th-logoonly.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const BrowserNavigation = () => {
  const logo = useStaticQuery(getImage)
  return (
    <NavigationWrapper className="d-none d-lg-flex">
      <Link to={links[0].path}>
        <Logo>
          <Image fluid={logo.heftigLogo.childImageSharp.fluid} alt="" />
        </Logo>
      </Link>
      <Sozial>
        <div>
          {socials.map((icon, index) => {
            return (
              <a
                key={index}
                href={icon.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {icon.icon}
              </a>
            )
          })}
        </div>
      </Sozial>
      <Navi>
        {links.map(link => {
          return (
            <Link key={link.text} to={link.path}>
              {link.text}
            </Link>
          )
        })}
        <a href="https:\\www.heftigeapps.de" target="_blank" rel="noreferrer">
          Heftige Apps
        </a>
      </Navi>
    </NavigationWrapper>
  )
}

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  color: red;
  min-height: 100px;
  background: rgba(20, 77, 101, 0.5);
  z-index: 99999;
  .gatsby-image-wrapper {
    img {
      border-radius: 200px;
    }
  }
`
const Navi = styled.div`
  margin-right: 30px;
  a {
    padding: 0 10px;
    font-weight: 700;
    text-decoration: none;
    color: ${variables.COLORS.mainWhite};
  }

  @media all and (-ms-high-contrast: none) {
    /* IE fix */
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    /* IE fix end */
  }
`

const Sozial = styled.div`
  a {
    padding: 0 5px;
  }
  @media all and (-ms-high-contrast: none) {
    /* IE fix */
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(0, -50%);
    /* IE fix end */
  }
`

const Logo = styled.div`
  justify-content: left;
  margin-left: 30px;
  min-width: 50px;
  @media all and (-ms-high-contrast: none) {
    /* IE fix */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    /* IE fix end */
  }
`

export default BrowserNavigation
