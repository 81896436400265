export default [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/#aboutme",
    text: "Über mich",
  },
  {
    path: "/#performances",
    text: "Leistungen",
  },
  {
    path: "/#waytogo",
    text: "Der Weg zur Homepage",
  },
  {
    path: "/#tools",
    text: "Werkzeug",
  },
  {
    path: "/#refs",
    text: "Referenzen",
  },
  {
    path: "/#contact",
    text: "Kontakt",
  },
]
